<template>
  <div class="note mb-2" v-bind="$attrs">
    <div class="text-center">
      <img class="mw-120" :src="iconUrl" :alt="noteKey">
      <p class="text-head">{{title}}</p>
    </div>
    <div class="col-12">
      <p class="edit-attach-text" v-if="inReadOnlyMode">{{ body ? body : 'Notes...' }}</p>
      <textarea v-if="inEditMode" rows="3" placeholder="Notes..." class="form-control" v-model="newContent"></textarea>
      <a v-if="inUploadMode" href="#" @click.prevent="cancel">Cancel Upload</a>
      <div class="edit-attach-set" v-if="enabled && canEditNote">
        <div v-if="inEditMode">
          <a href="#" @click.prevent="cancel"><img width="40" src="@images/close-icon.png" alt="Close icon"></a>
          <a href="#" @click.prevent="ok"><img width="40" src="@images/ok-icon@2x.png" alt="Save"></a>
        </div>
        <div v-if="inReadOnlyMode && isInactive && !locked">
          <a href="#" @click.prevent="edit"><img width="40" src="@images/edit-icon02.png" alt="Edit icon02"></a>
          <a href="#" @click.prevent="upload"><img width="40" src="@images/attach-icon02.png" alt="Attach icon02"></a>
        </div>
      </div>
      <note-image-upload v-if="inUploadMode" :note-key="noteKey" :reservation-id="reservationId" :booking="booking" file-field="attachment1" :attachment="attachment1" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
      <note-image-upload v-if="inUploadMode" :note-key="noteKey" :reservation-id="reservationId" :booking="booking" file-field="attachment2" :attachment="attachment2" :url="noteUploadUrl" @update="onUpload"></note-image-upload>
    </div>
    <p v-if="inReadOnlyMode" class="my-3">
      <a v-if="attachment1" target="_blank" :href="attachment1.url" class="note-img m-3 js-img"><img :src="attachment1.thumb.url"></a>
      <a v-if="attachment2" target="_blank" :href="attachment2.url" class="note-img m-3 js-img"><img :src="attachment2.thumb.url"></a>
    </p>
  </div>
</template>
<script>
import NoteImageUpload from './note_image_upload2.vue'
export default {
  inheritAttrs: false,
  props: {
    reservationId: {
      type: Number
    },
    booking: {
      type: Boolean,
      default: false
    },
    note: {
      type: Object,
      required: true
    },
    noteKey: {
      type: String,
      required: true
    },
    enabled: {
      type: Boolean,
      default: true
    },
    invalid: {
      type: Boolean,
      default: false
    }
  },
  components: { NoteImageUpload },
  data() {
    return {
      modeValue: '',
      newContent: null
    }
  },
  mounted() {
    this.newContent = this.note[this.noteKey].body
  },
  computed: {
    canEditNote() {
      if (this.locked) {
        return false
      }
      return this.isStaff || this.isClient
    },
    isInactive() {
      return this.$parent.editedNote == null
    },
    isActive() {
      return this.$parent.editedNote == this.noteKey
    },
    locked() {
      return this.$parent.readonly === true
    },
    noteData() {
      if (this.note) {
        return this.note[this.noteKey]
      }
      return {}
    },
    attachment1() {
      if (this.noteData.attachment1 && this.noteData.attachment1.url) {
        return this.noteData.attachment1
      }
    },
    attachment2() {
      if (this.noteData.attachment2 && this.noteData.attachment2.url) {
        return this.noteData.attachment2
      }
    },
    body() {
      return this.noteData.body
    },
    iconUrl() {
      return this.noteData.icon_url
    },
    mode: {
      set(v) {
        this.modeValue = v
      },
      get() {
        return this.modeValue
      }
    },
    noteUploadUrl() {
      return '/api/notes/' + this.note.id + '/upload'
    },
    inUploadMode() {
      return (this.mode == 'upload') && this.isActive && !this.invalid
    },
    inEditMode() {
      return (this.mode == 'edit') && this.isActive
    },
    inReadOnlyMode() {
      return this.mode == '' && !this.invalid
    },
    title() {
      return this.noteData.title
    },
    params() {
      var params = {}
      params[this.noteKey + '_body'] = this.newContent
      return params
    }
  },
  methods: {
    upload() {
      this.modeValue = 'upload'
      this.$parent.setNoteEdited(this.noteKey)
    },
    cancel() {
      this.newContent = this.body
      this.modeValue = ''
      this.$parent.setNoteEdited(null)
    },
    edit() {
      this.modeValue = 'edit'
      this.$parent.setNoteEdited(this.noteKey)
    },
    ok() {
      this.modeValue = ''
      this.$parent.saveNote(this.params)
    },
    onUpload(note) {
      this.modeValue = ''
      this.$parent.loadNote(note)
    }
  },
  watch: {
    locked(newValue) {
      if (newValue === true) {
        this.mode = ''
      }
    },
    invalid(newValue) {
      if (newValue === true) {
        this.modeValue = 'edit'
        this.$parent.editedNote = this.noteKey
      }
    }
  }
}
</script>
