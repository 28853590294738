<template>
  <div class="file-upload mb-3 block-images">
    <file-upload :reservation-id="reservationId" :url="url" @uploaded="onUploaded" :input-name="inputName">Upload file</file-upload>
    <a v-if="attachment" target="_blank" :href="attachment.url" class="note-img m-3 js-img"><img :src="attachment.thumb.url"></a>
  </div>
</template>
<script>
import FileUpload from '@/shared/upload2.vue'
export default {
  components: { FileUpload },
  props: {
    reservationId: {
      type: Number
    },
    domId: {
      type: String,
      default: 'location_attachment'
    },
    fileField: {
      type: String,
      default: 'location[attachment]'
    },
    url: {
      type: String,
      required: true
    },
    noteKey: {
      type: String,
      required: true
    },
    attachment: {
      type: Object
    }
  },
  computed: {
    keyName() {
      return this.noteKey + '_' + this.fileField
    },
    inputName() {
      return 'note[' + this.keyName + ']'
    }
  },
  methods: {
    onUploaded(response) {
      this.$emit('update', response.notes)
    }
  }
}
</script>
